import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
        this.init();
    }

    init() {
        const serviceWrap = document.querySelector(".js-index-service");
        const serviceSec = document.querySelectorAll(".js-index-service__sec");
        const serviceBtn = document.querySelectorAll(".js-service-button");
        const windowH = window.innerHeight;
        const scrollH = windowH * 3;
        const mediaQuery = window.matchMedia('(min-width: 1008px) and (min-height: 700px)')
        if (serviceWrap) {
            const servicePos = serviceWrap.getBoundingClientRect().top;
            if (mediaQuery.matches) {
                ScrollTrigger.create({
                    trigger: serviceWrap,
                    pin: true,
                    start: "top top",
                    end: `+=${scrollH}`,
                });
                for (let i = 0; i < serviceSec.length; i++) {
                    ScrollTrigger.create({
                        trigger: serviceSec[i],
                        start: `${windowH * i} top`,
                        end: `+=${windowH}`,
                        onEnter: () => {
                            for (let index = 0; index < serviceSec.length; index++) {
                                serviceSec[index].classList.remove("is-active");
                                serviceBtn[index].classList.remove("is-active");
                            }
                            serviceSec[i].classList.add("is-active");
                            serviceBtn[i].classList.add("is-active");
                        },
                        onEnterBack: () => {
                            for (let index = 0; index < serviceSec.length; index++) {
                                serviceSec[index].classList.remove("is-active");
                                serviceBtn[index].classList.remove("is-active");
                            }
                            serviceSec[i].classList.add("is-active");
                            serviceBtn[i].classList.add("is-active");
                        },
                    });
                }
                serviceBtn.forEach((element, index) => {
                    let scrollPos = servicePos + windowH * (index + 1);
                    element.addEventListener("click", () => {
                        window.scrollTo({
                            top: scrollPos,
                            //behavior: "smooth",
                        });
                    })
                });
            }
        }
	}
}