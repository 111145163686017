export default class {
	constructor() {
		this.accordion = ".js-accordion";
		this.trigger = ".js-accordion__trigger";
		this.contents = ".js-accordion__contents";
		this.init();
		this.gNavToggle();
		this.drawerToggle();
	}
	init() {
		const summaryAry = document.querySelectorAll(this.trigger);
		if (summaryAry) {
			summaryAry.forEach(element => {
				element.addEventListener("click", (event) => {
					// デフォルトの挙動を無効化
					event.preventDefault();

					const details = element.closest('.js-accordion');
					const content = details.querySelector('.js-accordion__contents');

					if (details.dataset.animStatus === "running") {
						return;
					}

					if (details.open) {
						const closingAnim = content.animate(closingAnimKeyframes(content), animTiming);
						details.dataset.animStatus = "running";
						closingAnim.onfinish = () => {
							details.removeAttribute("open");
							details.dataset.animStatus = "";
						};
					} else {
						details.setAttribute("open", "true");
						details.dataset.animStatus = "running";
						const openingAnim = content.animate(openingAnimKeyframes(content), animTiming);
						openingAnim.onfinish = () => {
							details.dataset.animStatus = "";
						};
					}
				});
			});
			const animTiming = {
				duration: 400,
				easing: "ease-out"
			};
			const closingAnimKeyframes = (content) => [
				{
					height: content.offsetHeight + 'px',
					opacity: 1,
				}, {
					height: 0,
					opacity: 0,
				}
			];
			const openingAnimKeyframes = (content) => [
				{
					height: 0,
					opacity: 0,
				}, {
					height: content.offsetHeight + 'px',
					opacity: 1,
				}
			];
		}
	}
	gNavToggle() {
		const trigger = document.querySelectorAll('.js-gnav-toggle__trigger');
		const content = document.querySelectorAll('.js-gnav-toggle__contents');
		this.toggleClass(trigger, content);
	}
	drawerToggle() {
		const trigger = document.querySelectorAll('.js-drawer-toggle__trigger');
		const content = document.querySelectorAll('.js-drawer-toggle__contents');
		this.toggleClass(trigger, content);
	}

	toggleClass(triggerAry, contentAry) {
		const activeClass = 'is-active';
		if (triggerAry) {
			triggerAry.forEach((element,index) => {
				element.addEventListener('click', () => {
					if (element.classList.contains(activeClass)) {
						triggerAry[index].classList.remove(activeClass);
						contentAry[index].classList.remove(activeClass);

					} else {
						for (let index = 0; index < triggerAry.length; index++) {
							triggerAry[index].classList.remove(activeClass);
						}
						for (let index = 0; index < contentAry.length; index++) {
							contentAry[index].classList.remove(activeClass);
						}
						triggerAry[index].classList.add(activeClass);
						contentAry[index].classList.add(activeClass);
					}
				})
			});
		}
	}
}