import { forEachChild } from "typescript";

export default class {
    constructor() {
        this.init();
    }
    init() {
        const modal = document.querySelectorAll('.js-modal');
        if (modal) {
            modal.forEach(element => {
                const bg = element.querySelector('.js-modal-bg');
                let trigger = element.querySelectorAll('.js-modal-trigger');
                let contents = element.querySelectorAll('.js-modal-contents');
                let wrap = element.querySelector('.js-modal-wap');
                let video = document.querySelectorAll('video');
                for (let index = 0; index < trigger.length; index++) {
                    trigger[index].addEventListener('click',() => {
                        wrap.classList.add('is-active');
                        let num = trigger[index].dataset.modalTrigger;
                        let current = document.querySelector('[data-modal-contents="'+ num +'"]');
                        for (let i = 0; i < contents.length; i++) {
                            contents[i].classList.remove('is-active');
                        }
                        current.classList.add('is-active')
                    })
                }
                bg.addEventListener('click', () => {
                    wrap.classList.remove('is-active');
                    for (let i = 0; i < contents.length; i++) {
                        contents[i].classList.remove('is-active');
                    }
                    for (let ii = 0; ii < video.length; ii++) {
                        video[ii].pause();
                    }
                })
            });
        }
	}
}